import { InstagramIcon, PDFIcon, PhoneIcon } from "../components/Icons";

const mainMenuItems: MenuItem[] = [
  {
    label: "About Us",
    url: "/about-us/"
  },
  {
    label: "Products",
    url: "/products/"
  },
  {
    label: "Why Timber?",
    url: "/timber/"
  },
  {
    label: "Contact Us",
    url: "/contact/"
  },
  {
    label: "Order Form",
    url: "/order-form.pdf",
    icon: PDFIcon,
    isExternal: true
  },
  {
    label: "Benchtop Order Form",
    url: "/benchtop-order-form.pdf",
    icon: PDFIcon,
    isExternal: true
  }
];

const contactMenuItems: MenuItem[] = [
  {
    label: "Gallery",
    url: "https://www.instagram.com/woodndoors_adelaide/",
    target: "_blank",
    icon: InstagramIcon,
    isExternal: true
  },
  {
    label: "08 8186 1733",
    url: "tel:+61881861733",
    icon: PhoneIcon,
    isExternal: true
  }
];

const footerMenuItems: MenuItem[] = [
  {
    label: "About Us",
    url: "/about-us/"
  },
  {
    label: "Products",
    url: "/products/"
  },
  {
    label: "Why Timber?",
    url: "/timber/"
  },
  {
    label: "Contact Us",
    url: "/contact/"
  }
];

export { mainMenuItems, contactMenuItems, footerMenuItems };
