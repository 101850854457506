import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Navbar, Nav } from "react-bootstrap";
import classnames from "classnames";
import { contactMenuItems, mainMenuItems } from "../data/menus";
import logo from "../assets/logo.svg";
import { CloseIcon, MapMarkerIcon } from "./Icons";

interface NavbarWrapperProps {
  pathname: string;
}

interface NavbarQueryProps {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

const NavbarWrapper: React.FC<NavbarWrapperProps> = ({ pathname }) => {
  const data: NavbarQueryProps = useStaticQuery(graphql`
    query NavbarQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const NavItems: React.FC<NavItemsProps> = ({
    menuItems,
    collapseLabel,
    asButtons
  }) => (
    <>
      {menuItems.map((menuItem, index) => {
        const isCurrentPath = menuItem.url == pathname;
        const Icon = menuItem.icon;
        return menuItem.isExternal ? (
          <Nav.Link
            key={`${index}`}
            href={menuItem.url}
            target={menuItem.target || ""}
            className={classnames(
              "d-flex align-items-center",
              isCurrentPath && "is-current-path",
              asButtons
                ? "btn btn-outline-light px-2 px-lg-3 ml-1 ml-sm-3"
                : "px-3 text-current"
            )}
          >
            {Icon && (
              <Icon
                size={18}
                className={classnames(
                  menuItem.label ? (collapseLabel ? "mr-lg-2" : "mr-2") : ""
                )}
              />
            )}
            {menuItem.label && (
              <span
                className={classnames(
                  "text-left",
                  Icon && collapseLabel && "d-none d-lg-inline"
                )}
              >
                {menuItem.label}
              </span>
            )}
          </Nav.Link>
        ) : (
          <Link
            key={`${index}`}
            to={menuItem.url}
            target={menuItem.target || ""}
            className={classnames(
              "nav-link",
              "d-flex align-items-center",
              isCurrentPath && "is-current-path",
              asButtons
                ? "btn btn-outline-light px-2 px-lg-3 ml-1 ml-sm-3"
                : "px-3 text-current"
            )}
          >
            {Icon && (
              <Icon
                size={18}
                className={classnames(
                  menuItem.label ? (collapseLabel ? "mr-lg-2" : "mr-2") : ""
                )}
              />
            )}
            {menuItem.label && (
              <span
                className={classnames(
                  "text-left",
                  Icon && collapseLabel && "d-none d-lg-inline"
                )}
              >
                {menuItem.label}
              </span>
            )}
          </Link>
        );
      })}
    </>
  );

  return (
    <div>
      <Navbar className="navbar-light p-0 w-100 d-block" expand="lg">
        <div
          className={`navbar-wrapper navbar-top bg-blue w-100 py-lg-2`}
          style={{
            zIndex: 1030
          }}
        >
          <div className="container pl-1 pr-1 pl-sm-0 pr-sm-3 py-1">
            <div className="d-flex w-100 align-items-center">
              <Link to="/" className="navbar-brand mr-1 flex-shrink-1">
                <img
                  className="mw-100"
                  src={logo}
                  alt={data.site.siteMetadata.title}
                  height="52"
                />
              </Link>
              <Nav className="ml-auto flex-row">
                <NavItems menuItems={contactMenuItems} collapseLabel />
              </Nav>
              <div className="d-none d-lg-flex ml-3 align-items-center">
                <MapMarkerIcon size={18} className="mr-2" />
                <div className="text-size-small">
                  38-40 Aldenhoven Road
                  <br />
                  Lonsdale, SA 5160
                </div>
              </div>
              <Navbar.Toggle
                className="ml-1 px-1 text-light"
                aria-controls="navbar-collapse"
              />
            </div>
          </div>
        </div>
        <div
          className={`navbar-wrapper navbar-bottom bg-light pb-3 pt-lg-2`}
          style={{
            zIndex: 1030
          }}
        >
          <div className="container px-0">
            <Navbar.Collapse
              id="navbar-collapse"
              className={classnames(
                "text-right",
                "mr-lg-3",
                "bg-light",
                "justify-content-center"
              )}
            >
              <div className="offcanvas-header d-flex d-lg-none align-items-center justify-content-end">
                <Navbar.Toggle aria-controls="navbar-collapse">
                  <CloseIcon size={36} />
                </Navbar.Toggle>
              </div>
              <Nav>
                <NavItems menuItems={mainMenuItems} />
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

interface NavItemsProps {
  menuItems: MenuItem[];
  collapseLabel?: boolean;
  asButtons?: boolean;
  className?: string;
}

export default NavbarWrapper;
