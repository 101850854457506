import React from "react";

export const CloseIcon: React.FC<IconProps> = ({ size, className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height={size || 24}
    width={size || 24}
    className={className}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      d={`
        M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 
        12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z`}
    />
  </svg>
);

export const NavIcon: React.FC<IconProps> = ({ size, className }) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    height={size || 24}
    width={size || 24}
    className={className}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

export const PhoneIcon: React.FC<IconProps> = ({ size, className }) => (
  <svg
    viewBox="0 0 512 512"
    fill="currentColor"
    height={size || 24}
    width={size || 24}
    className={className}
  >
    <path
      d={`
        M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3
        6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 
        177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6
        389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464
        0-11.2-7.7-20.9-18.6-23.4z`}
    />
  </svg>
);

export const InstagramIcon: React.FC<IconProps> = ({ size, className }) => {
  return (
    <svg
      viewBox="0 0 448 512"
      fill="currentColor"
      height={size || 24}
      width={(size || 24) * 0.875}
      className={className}
    >
      <path
        fill="currentColor"
        d={`
          M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 
          319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 
          0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 
          74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 
          0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 
          27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 
          0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 
          184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 
          184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 
          2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 
          11.7-99.5 9-132.1 9s-102.7 
          2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 
          9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 
          9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z`}
      ></path>
    </svg>
  );
};

export const MapMarkerIcon: React.FC<IconProps> = ({ size, className }) => {
  return (
    <svg
      viewBox="0 0 384 512"
      fill="currentColor"
      height={size || 24}
      width={(size || 24) * 0.75}
      className={className}
    >
      <path
        fill="currentColor"
        d={`
          M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 
          0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 
          13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 
          80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z`}
      ></path>
    </svg>
  );
};

export const PDFIcon: React.FC<IconProps> = ({ size, className }) => {
  return (
    <svg
      viewBox="0 0 384 512"
      fill="currentColor"
      height={size || 24}
      width={(size || 24) * 0.75}
      className={className}
    >
      <path
        fill="currentColor"
        d={`
          M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 
          48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 
          48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 
          76.1zM48 464V48h160v104c0 13.3 10.7 24 24 
          24h104v288H48zm250.2-143.7c-12.2-12-47-8.7-64.4-6.5-17.2-10.5-28.7-25-36.8-46.3 
          3.9-16.1 10.1-40.6 5.4-56-4.2-26.2-37.8-23.6-42.6-5.9-4.4 
          16.1-.4 38.5 7 67.1-10 23.9-24.9 56-35.4 74.4-20 10.3-47 26.2-51 
          46.2-3.3 15.8 26 55.2 76.1-31.2 22.4-7.4 46.8-16.5 68.4-20.1 18.9 
          10.2 41 17 55.8 17 25.5 0 28-28.2 17.5-38.7zm-198.1 77.8c5.1-13.7 
          24.5-29.5 30.4-35-19 30.3-30.4 35.7-30.4 35zm81.6-190.6c7.4 0 6.7 
          32.1 1.8 40.8-4.4-13.9-4.3-40.8-1.8-40.8zm-24.4 136.6c9.7-16.9 18-37 
          24.7-54.7 8.3 15.1 18.9 27.2 30.1 35.5-20.8 4.3-38.9 13.1-54.8 
          19.2zm131.6-5s-5 6-37.3-7.8c35.1-2.6 40.9 5.4 37.3 7.8z`}
      ></path>
    </svg>
  );
};
