import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import Root from "../components/Root";
import Navbar from "../components/Navbar";
import PageTitle from "../components/PageTitle";
import { InstagramIcon } from "../components/Icons";
import logo from "../assets/logo.svg";
import { footerMenuItems } from "../data/menus";

interface DefaultLayoutProps {
  pageTitle?: string;
  pathname: string;
  className?: string;
}

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      slogan: string;
      description: string;
      keywords: string;
    };
  };
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  className,
  pageTitle,
  pathname
}) => (
  <StaticQuery
    query={graphql`
      query OverrideDefaultLayoutQuery {
        site {
          siteMetadata {
            title
            description
            slogan
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => {
      const siteTitle = data.site.siteMetadata.title;
      const siteSlogan = data.site.siteMetadata.slogan;
      const isFront = pathname == "/";
      const isContact = pathname == "/contact";
      return (
        <Root className={className}>
          <Helmet
            title={
              isFront
                ? siteSlogan
                  ? `${siteTitle} | ${siteSlogan}`
                  : siteTitle
                : `${pageTitle} | ${siteTitle}`
            }
          >
            {isContact && (
              <script
                src="https://www.google.com/recaptcha/api.js"
                async
                defer
              ></script>
            )}
          </Helmet>
          <div className="page d-flex flex-column bg-light">
            <Navbar pathname={pathname} />
            <main className="main flex-grow-1">
              <div className="content">
                {pageTitle && <PageTitle>{pageTitle}</PageTitle>}
                {children}
              </div>
            </main>
            <div className="footer mt-5 bg-blue text-white">
              <div className="container py-5">
                <div className="pb-3">
                  <div className="d-flex justify-content-center">
                    <a
                      className="d-flex flex-column flex-sm-row align-items-center text-decoration-none text-current"
                      href="https://www.instagram.com/woodndoors_adelaide/"
                    >
                      <InstagramIcon size={32} />
                      <span className="ml-2 my-2 text-size-medium text-center">
                        Browse our Gallery on Instagram
                      </span>
                    </a>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-11 col-lg-8">
                    <div className="pt-3 border-top border-bottom">
                      <FooterNav menuItems={footerMenuItems} />
                    </div>
                  </div>
                </div>
                <div className="py-4">
                  <div className="text-center">
                    <span className="ml-2 my-2 text-size-medium text-center">
                      Showroom and office open by appointment only.
                    </span>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-sm-5 col-md-4 col-lg-3 mb-5 mb-sm-0">
                    <div className="text-center text-sm-left">
                      <Link to="/" className="">
                        <img
                          className="mw-100 mb-2"
                          src={logo}
                          alt={data.site.siteMetadata.title}
                          width="280"
                        />
                      </Link>
                      <p>
                        <strong>Address</strong>
                        <br />
                        38-40 Aldenhoven Road
                        <br />
                        Lonsdale, SA 5160
                      </p>
                      <p>
                        <strong>Phone</strong>
                        <br />
                        <a className="text-current" href="tel:+61881861733">
                          08 8186 1733
                        </a>
                      </p>
                      <p>
                        <strong>Email</strong>
                        <br />
                        <a
                          className="text-current"
                          href="mailto:sales@woodndoors.com"
                        >
                          sales@woodndoors.com
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-7 col-md-7 col-lg-5">
                    <div className="google-map w-100 h-100">
                      <iframe
                        className="w-100"
                        height="234"
                        frameBorder="0"
                        marginHeight={0}
                        marginWidth={0}
                        scrolling="no"
                        src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=en&amp;geocode=&amp;q=Wood+n+Doors+38-40+aldenhoven+road+lonsdale&amp;aq=&amp;sll=-34.218048,150.864258&amp;sspn=0.599019,0.76355&amp;vpsrc=0&amp;ie=UTF8&amp;hq=&amp;hnear=38%2F40+Aldenhoven+Rd,+Lonsdale+South+Australia+5160,+Australia&amp;t=m&amp;z=14&amp;ll=-35.116319,138.496754&amp;output=embed"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="boilerplate bg-blue text-white">
              <div className="container text-center">
                <div className="py-3">
                  <p className="m-0">
                    © {new Date().getFullYear()} {data.site.siteMetadata.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Root>
      );
    }}
  />
);

interface FooterNavProps {
  menuItems: MenuItem[];
}

const FooterNav: React.FC<FooterNavProps> = ({ menuItems }) => {
  const listItems = menuItems.map(menuItem => (
    <li className="col-6 col-sm-3 col-md-3" key={menuItem.label}>
      <Link
        className="d-block text-current text-center mb-3"
        to={menuItem.url as string}
      >
        <span>{menuItem.label}</span>
      </Link>
    </li>
  ));

  return <ul className="nav row justify-content-center">{listItems}</ul>;
};

export default DefaultLayout;
